import { ComponentPropsWithoutRef } from "react";
import { Notification } from "@finbricks/ui";
import { IconType } from "@finbricks/ui/src/Icon/Icon";
import useTranslation from "next-translate/useTranslation";
import { toast as toastOrigin, Toaster, ToasterProps, ToastOptions } from "react-hot-toast";

type NotificationProps = ComponentPropsWithoutRef<typeof Notification>;
type CustomToastOptions = ToastOptions & Partial<Pick<NotificationProps, "a11y" | "headline">> & { message: string };

function createHandler(color: NotificationProps["color"]) {
  const icon: Record<typeof color, IconType> = {
    green: "check_circle",
    red: "warning",
    blue: "information",
    yellow: "information",
    gray: "information",
  };

  return ({ a11y, headline, message, ...originOptions }: CustomToastOptions) => {
    return toastOrigin.custom((t) => {
      const id = t.id || t.createdAt.toString();
      const close = () => toastOrigin.dismiss(id);

      return (
        <Notification
          {...t.ariaProps}
          aria-live="assertive"
          role="status"
          icon={icon[color]}
          headline={headline}
          a11y={a11y || { closeLabel: "" }}
          color={color}
          className={t.visible ? "" : "hidden"} // disable animation
          style={t.style}
          onClose={close}
        >
          {message}
        </Notification>
      );
    }, originOptions);
  };
}

/*
 * Re-define origin toast to custom names.
 *
 * Examples of usage:
 * notify.positive({message: "My own message", duration: 5000, headline: "My Headline"});
 * */
const notification = {
  positive: createHandler("green"),
  negative: createHandler("red"),
  warning: createHandler("yellow"),
  neutral: createHandler("gray"),
} satisfies Record<string, ReturnType<typeof createHandler>>;

const actions = {
  remove: (notifyId: string) => toastOrigin.remove(notifyId),
  dismiss: (notifyId: string) => toastOrigin.dismiss(notifyId),
};

const notify = { ...notification, ...actions };

type NotificationsProviderProps = {
  position?: ToasterProps["position"];
  style?: ToasterProps["containerStyle"];
  className?: ToasterProps["containerClassName"];
  toastOptions?: Pick<ToastOptions, "duration" | "ariaProps"> & Pick<NotificationProps, "a11y">;
};
const NotificationsProvider = ({
  position = "top-right",
  style,
  className,
  toastOptions = { a11y: { closeLabel: "" } },
}: NotificationsProviderProps) => {
  const { t } = useTranslation();
  return (
    <Toaster
      position={position}
      containerStyle={style}
      containerClassName={className}
      toastOptions={
        {
          duration: 10000,
          ariaProps: toastOptions.ariaProps,
          a11y: { closeLabel: t("close") },
        } as typeof toastOptions
      }
    />
  );
};

export { NotificationsProvider, notify };
