
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Montserrat } from "@next/font/google";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import useTranslation from "next-translate/useTranslation";
import { CookieConsent } from "../modules/cookie-conset/components/CookieConsent";
import { NotificationsProvider } from "../utils/notification";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "../globals.css";
// If loading a variable font, you don't need to specify the font weight
const montserrat = Montserrat({ subsets: ["latin-ext"], display: "swap", fallback: ["sans-serif"] });
function MyApp({ Component, pageProps }: AppProps) {
    const { t } = useTranslation();
    return (<>
      <style jsx global>
        {`
          html {
            font-family: ${montserrat.style.fontFamily};
          }
        `}
      </style>

      <Script id={"analyticsScript"} type="text/plain" data-cookiecategory="analytics" async src="https://www.googletagmanager.com/gtag/js?id=G-CHF16G74HF"/>
      <Script id={"analyticsScriptGTAG"} type="text/plain" data-cookiecategory="analytics">
        {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-CHF16G74HF');`}
      </Script>

      <Head>
        <title>{t("title")}</title>
        {process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ? <meta name="robots" content="noindex"/> : null}
      </Head>

      <Component {...pageProps}/>

      <NotificationsProvider />

      <CookieConsent />
    </>);
}

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  