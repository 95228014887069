import "vanilla-cookieconsent";
import { useEffect } from "react";
import { useRouter } from "next/router";
import cookies_settings_cz from "./cookies_cs.json";
import cookies_settings_en from "./cookies_en.json";

export const CookieConsent = () => {
  const { locale } = useRouter();
  useEffect(() => {
    /**
     * useEffect is executed twice (React 18+),
     * make sure the plugin is initialized and executed once
     */
    if (!document.getElementById("cc--main")) {
      (window as any).CookieConsentApi = window.initCookieConsent();
      (window as any).CookieConsentApi.run(pluginConfig);
    }
    document.body.classList.add("finbricks_cookie_theme");
  }, []);

  //change language of cookie based on locale
  useEffect(() => {
    (window as any).CookieConsentApi.updateLanguage(locale);
  }, [locale]);

  return null;
};

const pluginConfig: UserConfig = {
  current_lang: "en",
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  gui_options: {
    consent_modal: {
      layout: "cloud", // box/cloud/bar
      position: "bottom center", // bottom/middle/top + left/right/center
      transition: "slide", // zoom/slide
      swap_buttons: false, // enable to invert buttons
    },
  },
  languages: {
    en: cookies_settings_en as unknown as LanguageSetting,
    cs: cookies_settings_cz as unknown as LanguageSetting,
  },
};
